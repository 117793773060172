import React from "react";

export function MainContainer() {
  return (
    <main className="container">
      <h1 style={{ fontSize: 50, color: "#49544d", textAlign: "center" }}>
        Strona w budowie
      </h1>
    </main>
  );
}
