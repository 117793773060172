import React, { useEffect, useState } from "react";
import background from "./images/background.png";
import { MainContainer } from "./components/MainContainer";

const Root = () => {
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = background;
    image.onload = () => {
      setLoaded(true);
    };
  }, []);

  if (!isLoaded) return null;
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          overflow: "hidden",
          position: "absolute",
          zIndex: -1,
          inset: 0,
          display: "flex",
        }}
      >
        <img
          src={background}
          style={{
            flex: 1,
            objectFit: "cover",
          }}
        />
      </div>
      <MainContainer />
    </div>
  );
};

export default Root;
